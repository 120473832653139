<script lang="ts">
  import _ from "lodash";

  import Genres from "./Genres.svelte";
  import Stats from "./Stats.svelte";
  import { selected_nodes } from "./store";
  import { NodeData } from "./ts/types";
  let metadata: NodeData;
  let genres = [];
  selected_nodes.subscribe((node_data: NodeData) => {
    metadata = node_data;
    if (node_data) {
      genres = _.clone(node_data.genres);
      if (node_data.year) {
        genres.push(node_data.year.toString());
      }
    }
  });
</script>

{#if metadata}
  <div>
    <a href={metadata.url} target="_blank" rel="noopener noreferrer">
      <img src={metadata.picture} alt="Thumbnail" />
    </a>
    <h1>{metadata.displayTitle()}</h1>
    <Stats {metadata} />

    <Genres {genres} />

    <div class="synopsis">
      <p>{metadata.synopsis}</p>
    </div>
  </div>
{/if}

<style lang="scss">
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 50%;
  }
  h1 {
    font-size: 1.7em;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h4 {
    padding-top: 0;
    margin: 0;
    font-weight: 50;
    opacity: 0.5;
  }
  a {
    color: var(--text-normal);
    text-decoration: none;
  }

  .synopsis {
    height: 300px;
    overflow: auto;
    padding-bottom: 0;
    font-size: 15px;
    color: var(--color-d-gray-10);
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    background-color: var(--text-normal);
    border-radius: 4px;
  }
</style>
