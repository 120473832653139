<script lang="ts">
  import NodeInfo from "./NodeInfo.svelte";
  import Canvas from "./Canvas.svelte";
  import Searchbar from "./Searchbar.svelte";
  import Sidebar from "./Sidebar.svelte";
  import SidebarHeader from "./SidebarHeader.svelte";
  import { METADATA } from "./ts/base_utils";
  import {
    completedList,
    distance,
    endYear,
    scoreThreshold,
    selected_nodes,
    startYear,
  } from "./store";
  import _ from "lodash";
  import { FullNode, Node } from "./ts/node";
  import Edges from "../../data-collection/data/edges.json";
  import Layout_ from "../../data-collection/data/layout.json";
  import { NodeData } from "./ts/types";

  let options = _.values(METADATA) as NodeData[];

  function onInit(nodes: FullNode[], node_map: { [id: number]: FullNode }) {
    function updateBrightness(
      node: FullNode,
      distance: number,
      scoreThreshold: number,
      startYear: number,
      endYear: number
    ) {
      const metadata = node.metadata as NodeData;
      const passingScore = metadata.score >= scoreThreshold;
      const yearInRange =
        metadata.year <= endYear && metadata.year >= startYear;
      const inRecs = node.recommendation_rank <= distance;

      if (passingScore && yearInRange && inRecs) {
        node.brightness = 1;
      } else {
        node.brightness = 0.5;
      }
    }

    function updateAllBrightness(nodes: FullNode[]) {
      nodes.forEach((node) => {
        updateBrightness(
          node,
          $distance,
          $scoreThreshold,
          $startYear,
          $endYear
        );
      });
    }

    distance.subscribe(() => updateAllBrightness(nodes));
    scoreThreshold.subscribe(() => updateAllBrightness(nodes));
    startYear.subscribe(() => updateAllBrightness(nodes));
    endYear.subscribe(() => updateAllBrightness(nodes));

    completedList.subscribe((list) => {
      const startNodes =
        list?.length > 0
          ? list.map((id) => node_map[id]).filter((node) => node)
          : nodes;
      Node.bfs(startNodes, nodes);
      updateAllBrightness(nodes);
    });
  }
</script>

<Canvas
  {onInit}
  {selected_nodes}
  Metadata_={METADATA}
  Edges={Edges.Edges}
  {Layout_}
/>

<Sidebar>
  <SidebarHeader
    slot="top-header"
    githubURL="https://github.com/platers/MAL-Map"
  />
  <Searchbar slot="searchbar" {selected_nodes} {options} />

  <NodeInfo slot="metadata-view" />
</Sidebar>

<style lang="scss">
</style>
