<script lang="ts">
  import _ from "lodash";
  import Autocomplete from "@smui-extra/autocomplete";
  import { NodeData } from "./ts/types";
  import { Writable } from "svelte/store";

  export let selected_nodes: Writable<NodeData>;
  export let options: NodeData[] = [];
  function getOptionLabel(option: NodeData): string {
    if (!option) {
      return "";
    }
    let label = "";
    if (option.englishTitle) {
      label += option.englishTitle;
    }
    if (option.title) {
      if (label) {
        label += " - ";
      }
      label += option.title;
    }
    return label;
  }

  let autocomplete_node: NodeData | null = null;
  $: if (autocomplete_node) {
    $selected_nodes = autocomplete_node;
  }
</script>

<div class="search-input">
  <Autocomplete
    {options}
    {getOptionLabel}
    showMenuWithNoInput={false}
    search={async (input) => {
      const low_input = input.toLowerCase();
      return options
        .filter((a) => {
          return getOptionLabel(a).toLowerCase().includes(low_input);
        })
        .sort((a, b) => {
          const aString = getOptionLabel(a).toLowerCase();
          const bString = getOptionLabel(b).toLowerCase();
          if (aString.startsWith(low_input) && !bString.startsWith(low_input)) {
            return -1;
          } else if (
            bString.startsWith(low_input) &&
            !aString.startsWith(low_input)
          ) {
            return 1;
          }
          return 0;
        })
        .slice(0, 10);
    }}
    bind:value={autocomplete_node}
    placeholder="Search"
    label="Search"
  />
  <i class="fa fa-search search-icon" />
</div>

<style lang="scss">
  .search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }

  .search-icon {
    position: absolute;
    right: -20px;
    margin-top: 10px;
  }
</style>
