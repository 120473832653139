"use strict";
exports.__esModule = true;
exports.NodeData = void 0;
var NodeData = /** @class */ (function () {
    function NodeData() {
    }
    NodeData.prototype.canonicalTitle = function () {
        return this.title.toLowerCase().replace(/[^a-z0-9]/g, '_');
    };
    NodeData.prototype.displayTitle = function () {
        var title = this.englishTitle || this.title;
        // discard after colon
        var colon = title.indexOf(': ');
        if (colon !== -1) {
            title = title.slice(0, colon);
        }
        return title;
    };
    return NodeData;
}());
exports.NodeData = NodeData;
;
